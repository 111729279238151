import React from 'react'
import type { ProfilePictureFragment } from 'apollo-gql'
import cn from 'classnames'

import ImgixImage from 'components/ImgixImage/ImgixImage'

import placeholderImage from './placeholder.jpg'

import styles from './AsyncProfilePicture.module.css'

type AsyncProfilePictureProps = {
  profilePicture: ProfilePictureFragment
  alt?: string
  style?: React.CSSProperties
  priority?: boolean
  roundedTop?: boolean
}

const AsyncProfilePicture: React.FC<AsyncProfilePictureProps> = ({
  profilePicture,
  alt = '',
  style = {},
  priority = false,
  roundedTop = false,
}) => {
  const imageUrl = profilePicture && profilePicture.cardUrl
  const backgroundColor =
    profilePicture && `rgb(${profilePicture.meanBackgroundColorHex})`

  return (
    <div
      style={{ backgroundColor, ...style }}
      className={cn(styles.wrapper, {
        [styles.roundedTop]: roundedTop,
      })}
    >
      {imageUrl ? (
        <ImgixImage
          src={imageUrl}
          alt={alt}
          layout="fill"
          priority={priority}
        />
      ) : (
        // eslint-disable-next-line @next/next/no-img-element
        <img src={placeholderImage} alt={alt} width={225} height={300} />
      )}
    </div>
  )
}

export default AsyncProfilePicture
