import type {
  FullPublicProfileProductFragment,
  PublicProfileProduct,
} from 'apollo-gql'
import {
  ProductProductTypeValueType,
  ProductSaleModeValueType,
  ProductVatTypeValueType,
} from 'lib/tracking/avo/sdk'

type ProfileProductAnalyticsProperties = {
  productProductType: ProductProductTypeValueType
  productId: string
  productSlug: string
  productSaleMode: ProductSaleModeValueType
  productVatType: ProductVatTypeValueType
  profileProductId: string
  profileProductPriceIncVatUsd: number
  profileProductAcceptingOrders: boolean
  profileProductPriceCurrency: string
  discountAmountUsd: number
  discountPercentage: number
  hasDiscount: boolean
}

const getProfileProductAnalyticsProperties = (
  profileProduct?:
    | Partial<FullPublicProfileProductFragment>
    | Partial<PublicProfileProduct>,
): ProfileProductAnalyticsProperties => {
  return {
    productProductType: profileProduct?.product
      ?.productType as ProductProductTypeValueType,
    productId: profileProduct?.id,
    productSlug: `${profileProduct?.product?.slug}`,
    productSaleMode: null,
    productVatType: null,
    profileProductId: profileProduct?.id,
    profileProductPriceIncVatUsd: profileProduct?.price?.valueInCent,
    profileProductAcceptingOrders: profileProduct?.acceptingOrders,
    profileProductPriceCurrency: profileProduct?.price?.currency,
    discountAmountUsd: profileProduct?.discount?.discountAmount.valueInCent,
    discountPercentage: profileProduct?.discount?.discountPercent,
    hasDiscount: !!profileProduct?.discount,
  }
}

export default getProfileProductAnalyticsProperties
