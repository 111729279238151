import React, { useRef, useState } from 'react'
import type {
  Amount,
  BlogPostFragment,
  PublicDiscount,
  PublicPotentialProfile,
  PublicProfile,
  PublicProfileProduct,
  RecommendedPageQuery,
} from 'apollo-gql'
import cn from 'classnames'
import { useTranslation } from 'lib/i18n'
import trackProfileProductCardPlayed from 'lib/tracking/avo/trackProfileProductCardPlayed'

import AsyncProfilePicture from 'components/AsyncProfilePicture/AsyncProfilePicture'
import DiscountPercentageBadge from 'components/DiscountPercentageBadge/DiscountPercentageBadge'
import Heading from 'components/Heading/Heading'
import Link from 'components/Link/Link'
import PlayIcon from 'components/PlayIcon/PlayIcon'
import PotentialProfileTag from 'components/PotentialProfileTag/PotentialProfileTag'
import ProfileCardBadgeWrapper from 'components/ProfileCardBadgeWrapper/ProfileCardBadgeWrapper'
import ProfileRating from 'components/ProfileRating/ProfileRating'
import VideoPlayer from 'components/VideoPlayer/VideoPlayer'
import usePushImpression from 'hooks/useImpressions'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useTrackAlgoliaSearch from 'hooks/useTrackAlgoliaSearch'

import { algoliaEventNames } from '@/constants'

import styles from './ProfileCard.module.css'

type PriceProps = {
  amount: Amount | Partial<Amount>
  discount: PublicDiscount
}

type PlayButtonProps = {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  isPlaying: boolean
  isLoading: boolean
}

type ProfileCardProps = {
  stripped?: boolean
  profile?:
    | PublicProfile
    | PublicPotentialProfile
    | BlogPostFragment['blogPost']['profiles'][0]

  profileProduct?:
    | BlogPostFragment['blogPost']['profiles'][0]['defaultProfileProduct']
    | RecommendedPageQuery['market']['recommendedProfileProducts'][0]
  collectionSlug?: string
  indexPositionInCollection?: number
  startIndexPositionInCollection?: number
  prioritizeImage?: boolean
}

const LONG_PRICE_CHAR_LIMIT = 6

// Gets the length of formatted value from value in cents as it removes cents if round number
// 123456 -> length is 6
// 123400 -> length is 4
const getLength = (value: number) =>
  value % 100 > 0 ? value.toString().length : (value / 100).toString().length

const Price: React.FC<PriceProps> = ({ amount, discount }) => {
  let priceIsLong = getLength(amount.valueInCent) >= LONG_PRICE_CHAR_LIMIT

  if (discount) {
    const formattedDiscount =
      discount.discountedPriceWithParent?.formattedValue ||
      discount.discountedPrice.formattedValue

    priceIsLong =
      getLength(discount.discountedPrice.valueInCent) >= LONG_PRICE_CHAR_LIMIT

    return (
      <div className={styles.priceWrapper}>
        <div
          className={cn(styles.originalPrice, {
            [styles.longPrice]: priceIsLong,
          })}
        >
          {amount.formattedValue}
        </div>
        <br />
        <div
          className={cn(styles.discountedPrice, {
            [styles.longPrice]: priceIsLong,
          })}
        >
          {formattedDiscount}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.priceWrapper}>
      <div
        className={cn(styles.price, {
          [styles.longPrice]: priceIsLong,
        })}
      >
        {amount.formattedValue}
      </div>
    </div>
  )
}

const PlayButton: React.FC<PlayButtonProps> = ({
  onClick,
  isPlaying,
  isLoading,
}) => (
  <button className={styles.playButton} onClick={onClick} type="button">
    <PlayIcon paused={isPlaying} loading={isLoading} />
  </button>
)

const ProfileCard: React.FC<ProfileCardProps> = ({
  stripped = false,
  profile: profilePayload,
  profileProduct,
  collectionSlug,
  indexPositionInCollection,
  startIndexPositionInCollection,
  prioritizeImage = false,
}) => {
  let profile = profilePayload
  const videoPlayerRef = useRef(null)
  const profileCardRef = useRef<HTMLAnchorElement>(null)

  const [isReady, setIsReady] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const { t } = useTranslation()

  const pushImpression = usePushImpression()
  const isVisible = useIntersectionObserver()
  const { trackAlgoliaEvent } = useTrackAlgoliaSearch()
  const notAvailableBadge = {
    badgeType: 'TEXT_BADGE',
    tagTitle: t('ProfileCard.notAvailableBadgeText', 'Not available'),
    backgroundColor: '#222',
  }
  if (!profile) profile = (profileProduct as PublicProfileProduct).profile
  const { slug, name, title, profilePicture, profileVideo, id } = profile
  const isPotentialProfile = profile.__typename === 'PublicPotentialProfile'

  const badges =
    !(profileProduct as PublicProfileProduct)?.acceptingOrders &&
    !isPotentialProfile
      ? [notAvailableBadge]
      : (profileProduct as PublicProfileProduct)?.badges

  const discount = (profileProduct as PublicProfileProduct)?.discount
  const price =
    (profileProduct as PublicProfileProduct)?.priceWithParent ||
    (profileProduct as PublicProfileProduct)?.price ||
    (profile.__typename !== 'PublicPotentialProfile' &&
      profile?.defaultProfileProduct?.price)

  let href = `/profile/${slug}`
  if ((profileProduct as PublicProfileProduct)?.priceWithParent) {
    href += `?profileProduct=${
      (profileProduct as PublicProfileProduct)?.product?.slug
    }`
  }
  if (profileProduct?.product?.productType === 'VIDEO_REQUEST_BUSINESS') {
    href = `/profile/${slug}/product/${profileProduct?.product?.slug}`
  }

  const publicProfileProduct = profileProduct as PublicProfileProduct
  const priority =
    prioritizeImage &&
    startIndexPositionInCollection === 1 &&
    indexPositionInCollection <= 1

  pushImpression(isVisible(profileCardRef, 0.7), {
    id: publicProfileProduct?.id,
    name: publicProfileProduct?.profile?.name,
    productId: publicProfileProduct?.product?.id,
    profileId: publicProfileProduct?.profile?.id,
    profileSlug: publicProfileProduct?.profile?.slug,
    productSlug: publicProfileProduct?.product?.slug,
    price: publicProfileProduct?.price?.valueInCent / 100 ?? 0,
    category: publicProfileProduct?.product?.productType,
    list: collectionSlug,
    position: startIndexPositionInCollection + indexPositionInCollection,
  })

  const handleOnClick = () =>
    trackAlgoliaEvent('clickedObjectIDs', {
      eventName: algoliaEventNames.PROFILE_CLICKED,
      objectIds: [id],
      profileId: id,
    })

  return (
    <Link href={href} prefetch={false} passHref>
      <a
        ref={profileCardRef}
        className={styles.profileCard}
        data-cy="profileCard"
        onClick={handleOnClick}
      >
        <div className={styles.media}>
          {profileVideo?.url && (
            <div className={styles.playerWrapper}>
              <VideoPlayer
                ref={videoPlayerRef}
                onPlay={() => {
                  setIsPlaying(true)
                }}
                onPause={() => setIsPlaying(false)}
                onReady={() => setIsReady(true)}
                style={{
                  display: isReady ? 'block' : 'none',
                }}
                src={profileVideo?.url}
                poster={profilePicture?.cardUrl}
                unmuteOnPlay
                loop={false}
                onMute={undefined}
                onUnmute={undefined}
              />
            </div>
          )}

          <AsyncProfilePicture
            profilePicture={profilePicture}
            alt={name}
            priority={priority}
            style={{
              display: isReady ? 'none' : 'block',
            }}
            roundedTop
          />

          {Array.isArray(badges) && badges.length > 0 && (
            <ProfileCardBadgeWrapper badges={badges} />
          )}

          {discount && (
            <DiscountPercentageBadge discount={discount} position="right" />
          )}
        </div>
        <div className={styles.aspect} />
        <main className={styles.content}>
          <div className={styles.priceAndPlayWrapper}>
            {price && !stripped && <Price amount={price} discount={discount} />}
            {isPotentialProfile && (
              <PotentialProfileTag profileName={profile.name} />
            )}

            {profileVideo && (
              <PlayButton
                isPlaying={isPlaying}
                isLoading={isPlaying && !isReady}
                onClick={(e) => {
                  videoPlayerRef.current?.playPause(e)
                  if (!isPlaying) {
                    trackProfileProductCardPlayed({
                      profile: profile as
                        | PublicProfile
                        | PublicPotentialProfile,
                      publicProfileProduct,
                      listPosition:
                        startIndexPositionInCollection +
                        indexPositionInCollection,
                    })
                  }
                }}
              />
            )}
          </div>

          <div
            className={cn(styles.details, {
              [styles.detailsStripped]: stripped,
            })}
          >
            <div className={styles.header}>
              <Heading level="h5" style={{ marginBottom: 4 }}>
                <h4 className={cn(styles.name, styles.lineClamp)}>{name}</h4>
              </Heading>

              <ProfileRating
                rating={(profile as PublicProfile).averageReviewRating}
              />
            </div>
            <div className={cn(styles.title, styles.lineClamp)}>{title}</div>
          </div>
        </main>
      </a>
    </Link>
  )
}

export default ProfileCard
