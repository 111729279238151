import React from 'react'
import cn from 'classnames'
import { lightBlue, white } from 'lib/theme'

import styles from './ProfileCardTextBadge.module.css'

const ProfileCardTextBadge = ({
  tagTitle,
  color = white,
  backgroundColor = lightBlue,
  size,
}) => (
  <div
    className={cn(styles.badgeText, {
      [styles[size]]: size,
    })}
    style={{
      color,
      backgroundColor,
    }}
  >
    <span style={styles.tagTitle}>{tagTitle}</span>
  </div>
)

export default ProfileCardTextBadge
