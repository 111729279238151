import { useEffect, useState } from 'react'
import { isServer } from 'lib/utils'

export default function useIntersectionObserver() {
  /**
   * @param {React.MutableRefObject<any>}  elementRef - A ref of any kind.
   * @param {number | number[]} threshold - Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed. If you only want to detect when visibility passes the 50% mark, you can use a value of 0.5. If you want the callback to run every time visibility passes another 25%, you would specify the array [0, 0.25, 0.5, 0.75, 1]. The default is 0 (meaning as soon as even one pixel is visible, the callback will be run). A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.
   * @returns {boolean} If the element is visible or not
   */
  const isVisible = (
    elementRef: React.MutableRefObject<any> | undefined,
    threshold: number | number[],
  ) => {
    if (isServer() || window.IntersectionObserver === undefined) {
      return
    }
    const [isIntersecting, setIsIntersecting] = useState(false)

    const observer = new IntersectionObserver(
      (entries, _) => {
        entries.forEach((entry) => {
          setIsIntersecting(entry.isIntersecting)
        })
      },
      { threshold },
    )

    useEffect(() => {
      if (elementRef && elementRef.current) {
        observer.observe(elementRef.current)
      }
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect()
      }
    }, [elementRef, isIntersecting])

    return isIntersecting
  }

  return isVisible
}
