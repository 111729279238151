import { useEffect } from 'react'
import {
  ImpressionItem,
  useImpressionContext,
} from 'contexts/ImpressionContext'

export default function useImpressions(): (
  isVisible: boolean,
  impression: ImpressionItem,
) => void {
  const usePushImpression = (
    isVisible: boolean,
    impression: ImpressionItem,
  ) => {
    const impressionContext = useImpressionContext()

    useEffect(() => {
      if (isVisible) {
        impressionContext.pushImpression({
          id: impression.id,
          name: impression.name,
          price: impression.price,
          category: impression.category,
          list: impression.list,
          position: impression.position,
          productId: impression.productId,
          profileId: impression.profileId,
          profileSlug: impression.profileSlug,
          productSlug: impression.productSlug,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible])
  }

  return usePushImpression
}
