import React from 'react'
import type { Scalars } from 'apollo-gql'

import { IconStarFull } from 'components/UI/Icons'

import styles from './ProfileRating.module.css'

type ProfileRatingProps = {
  rating: Scalars['Float']
}

const MIN_VISIBLE_RATING = 4

const ProfileRating = ({ rating }: ProfileRatingProps): JSX.Element => {
  if (!rating || rating < MIN_VISIBLE_RATING) {
    return null
  }

  return (
    <div className={styles.wrapper} data-testid="rating">
      <IconStarFull className={styles.star} height={10} />
      <div className={styles.number}>{rating.toFixed(1)}</div>
    </div>
  )
}

export default ProfileRating
