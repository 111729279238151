import type {
  FullPublicProfileFragment,
  PublicPotentialProfile,
  PublicProfile,
  PublicProfileProduct,
} from 'apollo-gql'
import { profileProductCardPlayed } from 'lib/tracking/avo/sdk'
import getProfileAnalyticsProperties from 'lib/tracking/avo/utils/getProfileAnalyticsProperties'
import getProfileProductAnalyticsProperties from 'lib/tracking/avo/utils/getProfileProductAnalyticsProperties'

import getMarketTrackingProperties from './utils/getMarketTrackingProperties'

type TrackProfileProductCardPlayedParams = {
  profile:
    | Partial<PublicProfile>
    | Partial<PublicPotentialProfile>
    | Partial<FullPublicProfileFragment>
  publicProfileProduct: Partial<PublicProfileProduct>
  listPosition: number
}

const trackProfileProductCardPlayed = ({
  profile,
  publicProfileProduct,
  listPosition,
}: TrackProfileProductCardPlayedParams): void => {
  profileProductCardPlayed({
    ...getProfileAnalyticsProperties(profile),
    ...getProfileProductAnalyticsProperties(publicProfileProduct),
    ...getMarketTrackingProperties(),
    productName: `${profile.slug} - ${
      publicProfileProduct?.product?.name || ''
    }`,
    listPosition,
  })
}

export default trackProfileProductCardPlayed
