import React from 'react'
import { useTranslation } from 'lib/i18n'

import styles from './PotentialProfileTag.module.css'

type PotentialProfileTagProps = {
  profileName: string
  style?: React.CSSProperties
}

const PotentialProfileTag: React.FC<PotentialProfileTagProps> = ({
  profileName,
  style,
}) => {
  const { t } = useTranslation()

  return (
    <span className={styles.tag} style={style}>
      {t('PotentialProfileTag.tagText', 'Not yet on memmo', { profileName })}
    </span>
  )
}

export default PotentialProfileTag
