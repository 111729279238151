import algoliasearch from 'algoliasearch/lite'
import config from 'lib/nextConfig'
import { isServer } from 'lib/utils'
import aa, { InsightsClient } from 'search-insights'

export const { ALGOLIA_APP_ID } = config
export const ALGOLIA_SEARCH_API_KEY = config.ALGOLIA_PUBLIC_API_KEY

export const INDEX_PREFIX = config.ALGOLIA_INDEX_PREFIX

export const searchClient = algoliasearch(
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_API_KEY,
)

let initiated = false
export const getInitiatedInsightsClient = (): InsightsClient => {
  if (!initiated && !isServer()) {
    aa('init', {
      appId: ALGOLIA_APP_ID,
      apiKey: ALGOLIA_SEARCH_API_KEY,
      useCookie: true,
    })

    aa(
      'onUserTokenChange',
      (userToken) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer = window.dataLayer || []
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dataLayer.push({
          algoliaUserToken: userToken,
        })
      },
      { immediate: true },
    )
    initiated = true
  }
  return aa
}

getInitiatedInsightsClient()
