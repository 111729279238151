import { INDEX_PREFIX } from 'lib/algolia'

import useLanguage from 'hooks/useLanguage'
import useMarket from 'hooks/useMarket'

type AlgoliaIndices = {
  profilesIndex: string
  potentialProfilesIndex: string
  profilesIndexPriceAsc: string
  profilesIndexPriceDesc: string
}

const useAlgoliaIndices = (): AlgoliaIndices => {
  const { id: marketId } = useMarket()
  const { id: languageId } = useLanguage()

  return {
    profilesIndex:
      `${INDEX_PREFIX}-${marketId}-${languageId}-profiles`.toLowerCase(),
    potentialProfilesIndex:
      `${INDEX_PREFIX}-${marketId}-${languageId}-potential-profiles`.toLowerCase(),
    profilesIndexPriceAsc:
      `${INDEX_PREFIX}-${marketId}-${languageId}-profiles-price-asc`.toLowerCase(),
    profilesIndexPriceDesc:
      `${INDEX_PREFIX}-${marketId}-${languageId}-profiles-price-desc`.toLowerCase(),
  }
}

export default useAlgoliaIndices
