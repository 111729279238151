import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'lib/i18n'
import Image from 'next/image'

import Heading from 'components/Heading/Heading'
import Spinner from 'components/Spinner/Spinner'

import styles from './VideoNotReady.module.css'

function VideoNotReady({ thumbnailUrl = undefined, children = undefined }) {
  const { t } = useTranslation()
  const bgStyles = []
  if (thumbnailUrl) bgStyles.push(styles.wrappertransparent)
  const wrapperStyles = cn([styles.wrapper, ...bgStyles])

  return (
    <>
      {thumbnailUrl && <Image src={thumbnailUrl} layout="fill" alt="Video" />}
      {children}
      <div className={wrapperStyles}>
        <div>
          <Spinner
            centered
            style={{
              fontSize: 70,
              marginBottom: 20,
              color: 'white',
            }}
          />
          <Heading className={styles.heading} color="light">
            {t('MemmoPlayer.notReady', 'Video is processing, almost there!')}
          </Heading>
        </div>
      </div>
    </>
  )
}

VideoNotReady.propTypes = {}

export default VideoNotReady
