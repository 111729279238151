/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useGeneralContext } from 'contexts/GeneralContext'
import { logger } from 'lib/logger'

import VideoNotReady from 'components/VideoNotReady/VideoNotReady'
import useEffectOnce from 'hooks/useEffectOnce'
import useResponsive from 'hooks/useResponsive'

import styles from './VideoPlayer.module.css'

const VideoPlayer = (
  {
    src,
    poster,
    autoPlay = false,
    loop = true,
    onPlay,
    onPause,
    onMute,
    onUnmute,
    unmuteOnPlay = false,
    style,
    onReady,
    showVideoElementInitial = false,
    fullScreenOnPlay = false,
    fullScreenOnAutoPlay = false,
    onFullscreenExit = () => {},
    onVideoEnd = () => {},
  },
  ref,
) => {
  const uid = src
  const { videoIdPlaying, setVideoIdPlaying } = useGeneralContext()
  const { isMuted, setIsMuted } = useGeneralContext()
  const [localIsPlaying, setLocalIsPlaying] = useState(false)
  const [showVideoElement, setShowVideoElement] = useState(false)
  const [playOnVideoElementRender, setPlayOnVideoElementRender] =
    useState(false)
  const [blockedAutoplayMuted, setBlockedAutoplayMuted] = useState(false)
  const videoPlayer = useRef(null)
  const hls = useRef(null)
  const { isMobile } = useResponsive()
  // TODO: Might make more sense to move this to the API
  const isHLSSrc = /.m3u8/.test(src)

  // Mute / Unmute
  const toggleMute = (e) => {
    if (e) e.stopPropagation()

    const shouldBeMuted =
      blockedAutoplayMuted && isMuted === undefined ? false : !isMuted

    setIsMuted(shouldBeMuted)
    setBlockedAutoplayMuted(false)
    if (shouldBeMuted) {
      if (onMute) {
        onMute()
      }
    } else if (onUnmute) {
      onUnmute()
    }
  }

  // Open in fullscreen
  const showFullscreen = (e) => {
    if (e) e.stopPropagation()

    if (videoPlayer.current.requestFullscreen) {
      videoPlayer.current.requestFullscreen()
    } else if (videoPlayer.current.mozRequestFullScreen) {
      /* Firefox */
      videoPlayer.current.mozRequestFullScreen()
    } else if (videoPlayer.current.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      videoPlayer.current.webkitRequestFullscreen()
    } else if (videoPlayer.current.webkitEnterFullscreen) {
      /* iOSSafari */
      videoPlayer.current.webkitEnterFullscreen()
    } else if (videoPlayer.current.msRequestFullscreen) {
      /* IE/Edge */
      videoPlayer.current.msRequestFullscreen()
    }
  }

  // Toggle play and pause on video
  const playPause = useCallback(
    ({ e, pausedByOtherPlayer = false } = {}) => {
      if (e) e.preventDefault()

      // If we haven't rendered the video element yet then render it
      // setVideoPlayer will trigger the play for us once element is rendered
      if (!showVideoElement && !autoPlay) {
        setPlayOnVideoElementRender(true)
        setShowVideoElement(true)
      }

      if (!videoPlayer.current) return

      if (localIsPlaying) {
        videoPlayer.current.pause()
        if (!pausedByOtherPlayer) {
          setVideoIdPlaying(null)
        }
      } else if (fullScreenOnPlay && isMobile) {
        videoPlayer.current.play().then(() => showFullscreen())
      } else {
        videoPlayer.current.play()
      }
    },
    [
      showVideoElement,
      autoPlay,
      localIsPlaying,
      setVideoIdPlaying,
      fullScreenOnPlay,
      isMobile,
    ],
  )

  // This allows us to run code when the video player embed is mounted which we do after clicking play
  const setVideoPlayer = useCallback(
    (node) => {
      // Save a reference to the node
      videoPlayer.current = node

      // If it should trigger a play after the video embed element has been rendered.
      // For HLS sources this is handled after hls support has been setup
      if (videoPlayer.current && playOnVideoElementRender && !isHLSSrc) {
        setPlayOnVideoElementRender(() => {
          playPause()
          return false
        })
      }
    },
    [isHLSSrc, playOnVideoElementRender, playPause],
  )

  // Make it possible to do
  // <VideoPlayer ref={videoPlayerRef} />
  // videoPlayerRef.current.playPause()
  useImperativeHandle(ref, () => ({
    playPause(e) {
      return playPause({ e })
    },
    toggleMute(e) {
      return toggleMute(e)
    },
    showFullscreen(e) {
      return showFullscreen(e)
    },
  }))

  // Handle incoming plays from other VideoPlayer and pause this one if needed
  useEffect(() => {
    if (localIsPlaying && videoIdPlaying !== uid) {
      playPause({ pausedByOtherPlayer: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoIdPlaying, localIsPlaying, playPause])

  // Handle autoplay to set correct context.
  useEffectOnce(() => {
    if (autoPlay === true && !localIsPlaying) {
      setVideoIdPlaying(uid)

      const playPromise = videoPlayer.current.play()
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Autoplay started!
            if (fullScreenOnAutoPlay) {
              showFullscreen()
            }
          })
          .catch((error) => {
            logger.info(error)
            // Retry playing muted
            setBlockedAutoplayMuted(true)
            setIsMuted(true)
            if (onMute) {
              onMute()
            }
            if (videoPlayer.current) {
              videoPlayer.current.play()
            }
          })
      }
    }
  })

  useEffect(() => {
    const handleLoadedData = ({ eventPhase }) => {
      if (eventPhase >= 2 && onReady) onReady()
    }

    if (videoPlayer.current) {
      videoPlayer.current.addEventListener(
        'loadeddata',
        handleLoadedData,
        () => {
          if (videoPlayer.current) {
            videoPlayer.current.removeEventListener('loadeddata')
          }
        },
      )
    }
  }, [onReady, videoPlayer])

  useEffectOnce(() => {
    const onFullscreenChange = () => {
      if (!document.fullscreenElement) {
        onFullscreenExit()
      }
    }

    if (videoPlayer.current) {
      ;[
        'fullscreenchange',
        'webkitendfullscreen',
        'mozfullscreenchange',
      ].forEach((event) => {
        videoPlayer.current.addEventListener(event, onFullscreenChange, () => {
          if (videoPlayer.current) {
            videoPlayer.current.removeEventListener(event)
          }
        })
      })
    }
  })

  const onVideoEndCallback = useCallback(() => {
    if (onVideoEnd) {
      onVideoEnd()
    }
  }, [onVideoEnd])

  // Add HLS Support
  useEffect(() => {
    async function setupHLSIfNeeded() {
      if (videoPlayer.current) {
        const video = videoPlayer.current
        if (showVideoElement && !isHLSSrc) video.src = src
        if (showVideoElement && isHLSSrc) {
          if (video.canPlayType('application/vnd.apple.mpegurl')) {
            // This will run in safari, where HLS is supported natively
            video.src = src
          } else {
            const Hls = (await import('hls.js')).default
            if (Hls.isSupported()) {
              // This will run in all other modern browsers
              hls.current = new Hls()
              hls.current.attachMedia(videoPlayer.current)
              hls.current.loadSource(src)
            }
          }

          if (playOnVideoElementRender) {
            setPlayOnVideoElementRender(() => {
              playPause()
              return false
            })
          }
        }
      }
    }
    setupHLSIfNeeded()

    return () => {
      if (hls.current) {
        hls.current.destroy()
      }
    }
    // we don't care about playOnVideoElementRender or playPause changing,
    // adding these will cause HLS support to re-enable which we don't want
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoPlayer, src, isHLSSrc, showVideoElement])

  if (!src) return <VideoNotReady />

  return (
    <div
      onClick={playPause}
      className={styles.container}
      role="button"
      tabIndex={0}
    >
      {(showVideoElement || autoPlay || showVideoElementInitial) && (
        <video
          style={style}
          onPlay={() => {
            setLocalIsPlaying(true)
            if (unmuteOnPlay && isMuted) {
              toggleMute()
            }
            setVideoIdPlaying(uid)
            if (onPlay) {
              onPlay()
            }
          }}
          onPause={() => {
            setLocalIsPlaying(false)
            setBlockedAutoplayMuted(false)
            if (onPause) {
              onPause()
            }
          }}
          className={styles.player}
          ref={setVideoPlayer}
          poster={poster}
          playsInline
          loop={loop}
          autoPlay={autoPlay}
          muted={blockedAutoplayMuted || isMuted}
          data-cy="videoPlayer"
          onEnded={onVideoEndCallback}
        />
      )}
    </div>
  )
}

export default forwardRef(VideoPlayer)
