import { getInitiatedInsightsClient } from 'lib/algolia'
import { getItem, setItem } from 'lib/localStorage'

import useAlgoliaIndices from 'hooks/useAlgoliaIndices'

import { AlgoliaEventNames } from '../constants'

type EventTypeProps =
  | 'convertedObjectIDsAfterSearch'
  | 'viewedObjectIDs'
  | 'clickedObjectIDs'

export interface TrackAlgoliaEventPayloadProps {
  userToken?: string
  eventName: AlgoliaEventNames
  index?: string
  queryId?: string
  objectIds?: string[]
  profileId?: string
  profileIds?: string[]
}

interface HitLocalstorageDataProps {
  objectId: string
  queryId: string
  index: string
  profileId: string
}

interface AlgoliaPayloadProps {
  index: string
  eventName: string
  objectIDs: string[]
  userToken: string
  queryID?: string
}

const LOCALSTORAGE_KEY = 'lastAlgoliaHitClicked'

const writeHitToLocalStorage = (data: HitLocalstorageDataProps) => {
  setItem(LOCALSTORAGE_KEY, JSON.stringify(data))
}

const readHitFromLocalStorage: (
  profileId?: string,
) => HitLocalstorageDataProps | null = (profileId) => {
  if (!profileId) {
    return null
  }
  const rawData = getItem(LOCALSTORAGE_KEY)
  if (!rawData) {
    return null
  }
  let parsedData
  try {
    parsedData = JSON.parse(rawData)
  } catch (e) {
    return null
  }
  if (
    !parsedData.objectId ||
    !parsedData.queryId ||
    !parsedData.index ||
    parsedData.profileId !== profileId
  ) {
    return null
  }
  return {
    objectId: parsedData.objectId,
    queryId: parsedData.queryId,
    index: parsedData.index,
    profileId: parsedData.profileId,
  }
}

export const isLastAlgoliaHitClicked = (profileId: string) => {
  return !!readHitFromLocalStorage(profileId)
}

type UseTrackAlgoliaSearchProps = () => {
  trackAlgoliaEvent: {
    <T extends 'convertedObjectIDsAfterSearch'>(
      event: T,
      payload: TrackAlgoliaEventPayloadProps,
    ): void
    <T extends 'viewedObjectIDs'>(
      event: T,
      payload: Omit<TrackAlgoliaEventPayloadProps, 'queryId'>,
    ): void
    <T extends 'clickedObjectIDs'>(
      event: T,
      payload: Omit<TrackAlgoliaEventPayloadProps, 'queryId'>,
    ): void
  }
  storeSearchHitClick: (
    queryId: string,
    objectId: string,
    profileId: string,
    index?: string,
  ) => void
}

const useTrackAlgoliaSearch: UseTrackAlgoliaSearchProps = () => {
  const { profilesIndex } = useAlgoliaIndices()
  function trackAlgoliaEvent<T extends 'convertedObjectIDsAfterSearch'>(
    event: T,
    payload: TrackAlgoliaEventPayloadProps,
  ): void
  function trackAlgoliaEvent<T extends 'viewedObjectIDs'>(
    event: T,
    payload: Omit<TrackAlgoliaEventPayloadProps, 'queryId'>,
  ): void
  function trackAlgoliaEvent<T extends 'clickedObjectIDs'>(
    event: T,
    payload: Omit<TrackAlgoliaEventPayloadProps, 'queryId'>,
  ): void
  function trackAlgoliaEvent(
    event: EventTypeProps,
    payload: TrackAlgoliaEventPayloadProps,
  ) {
    const profileIds = new Set(payload.profileIds || [])
    if (payload.profileId) {
      profileIds.add(payload.profileId)
    }
    profileIds.forEach((profileId) => {
      const possibleStoredSearchHit = readHitFromLocalStorage(profileId)
      const aa = getInitiatedInsightsClient()
      const queryID =
        payload.queryId || possibleStoredSearchHit?.queryId?.toString()
      const algoliaPayload: AlgoliaPayloadProps = {
        index: payload.index || possibleStoredSearchHit?.index || profilesIndex,
        eventName: payload.eventName,
        objectIDs:
          payload.objectIds ||
          [possibleStoredSearchHit?.objectId?.toString()].filter(Boolean),
        userToken: payload.userToken,
      }

      if (queryID) {
        algoliaPayload.queryID = queryID
      }

      if (
        algoliaPayload.index &&
        algoliaPayload.eventName &&
        algoliaPayload.objectIDs?.length
      ) {
        aa(event, algoliaPayload)
      }
    })
  }

  const storeSearchHitClick = (
    queryId: string,
    objectId: string,
    profileId: string,
    index: string = profilesIndex,
  ) => {
    writeHitToLocalStorage({ queryId, objectId, index, profileId })
  }

  return { trackAlgoliaEvent, storeSearchHitClick }
}

export default useTrackAlgoliaSearch
