import type {
  FullPublicPotentialProfileFragment,
  FullPublicProfileFragment,
  PublicPotentialProfile,
  PublicProfile,
} from 'apollo-gql'

type ProfileAnalyticsProperties = {
  profileTags: string[] | null | undefined
  profileId: string | null | undefined
  profileSlug: string | null | undefined
  profileAcceptingOrders: boolean | null | undefined
  profileName: string
  profileOnboardingCompletedAt: string | null | undefined
}

function isProfile(
  toBeDetermined?:
    | Partial<FullPublicProfileFragment>
    | Partial<PublicProfile>
    | Partial<PublicPotentialProfile>
    | Partial<FullPublicPotentialProfileFragment>,
): toBeDetermined is Partial<FullPublicProfileFragment> {
  if (!toBeDetermined) {
    return false
  }
  return (
    (toBeDetermined as PublicProfile).acceptingOrders ||
    (toBeDetermined as PublicProfile).acceptingOrders === false
  )
}

const getProfileAnalyticsProperties = (
  profile?:
    | Partial<FullPublicProfileFragment>
    | Partial<PublicProfile>
    | Partial<PublicPotentialProfile>
    | Partial<FullPublicPotentialProfileFragment>,
): ProfileAnalyticsProperties => {
  return {
    profileTags: null,
    profileId: profile?.id ?? null,
    profileSlug: profile?.slug ?? null,
    profileAcceptingOrders: isProfile(profile)
      ? profile.acceptingOrders
      : false,
    profileName: profile?.name ?? null,
    profileOnboardingCompletedAt: null,
  }
}

export default getProfileAnalyticsProperties
