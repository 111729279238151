import React from 'react'
import { logger } from 'lib/logger'

import ProfileCardImageBadge from './ProfileCardImageBadge'
import ProfileCardTextBadge from './ProfileCardTextBadge'

export default function ProfileCardBadge({ badge, size = 'default' }) {
  if (!badge) return null
  const { badgeType, tagTitle, backgroundColor, color, image } = badge

  if (badgeType === 'TEXT_BADGE') {
    return (
      <ProfileCardTextBadge
        tagTitle={tagTitle}
        color={color}
        backgroundColor={backgroundColor}
        size={size}
      />
    )
  }

  if (badgeType === 'IMAGE_BADGE' && image) {
    return <ProfileCardImageBadge imageUrl={image.url} tagTitle={tagTitle} />
  }

  logger.warn('Invalid badgeType sent to ProfileCardBadge - got ', badgeType)
  return null
}
