import React from 'react'
import { faPauseCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { darkGrey, white } from 'lib/theme'

import Spinner from 'components/Spinner/Spinner'

import styles from './PlayIcon.module.css'

interface IconProps {
  backgroundColor?: string
  iconColor?: string
  size?: number
  paused?: boolean
  loading?: boolean
  hidePauseButtonWhenPlaying?: boolean
  className?: string
  pauseOnBottomRight?: boolean
}

interface PlayIconOnlyProps extends IconProps {
  isVideoEnded?: never
  icon?: never
  text?: never
  textColor?: never
}
interface PlayAgainWithIconProps extends IconProps {
  isVideoEnded: boolean
  icon: React.ReactNode
  text: string
  textColor?: string
}

type PlayIconProps = PlayIconOnlyProps | PlayAgainWithIconProps

const PlayIcon = ({
  backgroundColor = white,
  iconColor = darkGrey,
  size = 32,
  paused = false,
  loading = false,
  hidePauseButtonWhenPlaying = false,
  className = '',
  isVideoEnded = false,
  icon = undefined,
  text = undefined,
  textColor = white,
  pauseOnBottomRight,
}: PlayIconProps): JSX.Element => {
  if (loading) {
    return (
      <div
        className={styles.pause}
        style={{ fontSize: size - 6 }}
        data-cy="videoLoadingIcon"
      >
        <Spinner color={white} />
      </div>
    )
  }

  if (paused) {
    if (hidePauseButtonWhenPlaying) return null
    return (
      <div
        className={cn(styles.play, {
          [styles.pauseOnBottomRight]: pauseOnBottomRight,
        })}
        style={{ fontSize: size }}
      >
        <FontAwesomeIcon icon={faPauseCircle} color={white} />
      </div>
    )
  }

  if (isVideoEnded) {
    return (
      <span className={styles.replay} style={{ color: textColor }}>
        {icon} {text}
      </span>
    )
  }

  return (
    <span className={cn(styles.play, className)}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="16"
          cy="16"
          r="15.5"
          fill={backgroundColor}
          stroke={backgroundColor}
        />
        <path
          d="M21 14.2679C22.3333 15.0378 22.3333 16.9623 21 17.7321L14.25 21.6292C12.9167 22.399 11.25 21.4367 11.25 19.8971L11.25 12.1029C11.25 10.5633 12.9167 9.60103 14.25 10.3708L21 14.2679Z"
          fill={iconColor}
        />
      </svg>
    </span>
  )
}

export default PlayIcon
