import React from 'react'

import styles from './ProfileCardImageBadge.module.css'

export default function ProfileCardImageBadge({ tagTitle, imageUrl }) {
  return (
    <div className={styles.badgeImage}>
      <img
        src={imageUrl}
        alt={tagTitle}
        title={tagTitle}
        className="noHoverEffect"
      />
    </div>
  )
}
