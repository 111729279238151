import React from 'react'
import cn from 'classnames'
import { red } from 'lib/theme'

import styles from './DiscountPercentageBadge.module.css'

export default function DiscountPercentageBadge({
  discount,
  style: styleProps = {},
  backgroundColor = red,
  position = 'top',
}) {
  if (!discount || !discount.discountPercent) return null

  // Do not show discount percentage badge if the discount is lower than 10%
  if (parseInt(discount.discountPercent, 10) <= 9) return null

  const style = {
    backgroundColor,
    ...styleProps,
  }

  return (
    <div
      className={cn(styles.discountPercentageBadge, {
        [styles.positionedTop]: position === 'top',
        [styles.positionedRight]: position !== 'top',
      })}
      style={style}
    >
      <div className={styles.text}>{-discount.discountPercent}%</div>
    </div>
  )
}
