import React from 'react'

import ConditionalWrapper from 'components/ConditionalWrapper/ConditionalWrapper'
import { IconSpinnerThird } from 'components/UI/Icons'

import styles from './Spinner.module.css'

interface SpinnerProps {
  color?: string
  style?: React.CSSProperties
  centered?: boolean
  height?: number
}

const Spinner: React.FC<SpinnerProps> = ({
  color = '',
  style = {},
  height = 14,
  centered = false,
}) => (
  <ConditionalWrapper
    condition={centered}
    wrapper={(children: React.ReactNode) => {
      return <div className={styles.wrapper}>{children}</div>
    }}
  >
    <IconSpinnerThird
      className={styles.spinIcon}
      height={height}
      color={color}
      style={style}
    />
  </ConditionalWrapper>
)

export default Spinner
