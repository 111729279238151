type ImageLoaderProps = {
  src: string
  width: number
  quality?: number
}

// Use instead of the default next.js loader to optimize images with absolute imgix URLs
// https://nextjs.org/docs/basic-features/image-optimization#loaders
//
// It will add srcset based on the next.js device size settings
// https://nextjs.org/docs/api-reference/next/image#device-sizes
function imgixLoader({ src, width, quality }: ImageLoaderProps): string {
  const url = new URL(src)
  const params = url.searchParams

  params.set('auto', params.getAll('auto').join(',') || 'format')
  params.set('fit', params.get('fit') || 'max')
  params.set('w', params.get('w') || width.toString())

  if (quality) {
    params.set('q', quality.toString())
  }

  return url.href
}

export default imgixLoader
