import React from 'react'
import cn from 'classnames'

import ProfileCardBadge from 'components/ProfileCardBadge/ProfileCardBadge'

import styles from './ProfileCardBadgeWrapper.module.css'

export default function ProfileCardBadgeWrapper({ badges }) {
  // only support one badge for now
  const badge = badges[0]
  const badgePosition = badge.badgePosition || 'TOP_LEFT'

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.positionTopLeft]: badgePosition === 'TOP_LEFT',
        [styles.positionTopRight]: badgePosition === 'TOP_RIGHT',
      })}
    >
      <ProfileCardBadge badge={badge} />
    </div>
  )
}
